import React from "react";
import { Container, Typography } from "@material-ui/core";

const AccessDenied = () => (
  <Container>
    <Typography variant="h2">{I18n.t("access_denied")}</Typography>
    <Typography variant="body1">{I18n.t("access_denied_message")}</Typography>
  </Container>
);

export default AccessDenied;
