// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Loading from "@components/common/loading_indicator";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import Router from "@routes/router";
import { Routes, fetchJson, submitJson, theme } from "@helpers";

const Application = () => {
  const [locale, setLocale] = useState();
  const [userLoaded, setUserLoaded] = useState(false);

  const fetchTranslations = () =>
    fetchJson(Routes.translations_path(), (result) => {
      const { keys, locale, available_locales, default_locale, fallbacks } = result;
      I18n.keys = keys;
      I18n.locale = locale;
      I18n.available_locales = available_locales;
      I18n.default_locale = default_locale;
      I18n.fallbacks = fallbacks;

      setLocale(I18n.locale);
    });

  const handleLocaleChange = (e, newLocale) => {
    I18n.locale = newLocale;
    setLocale(newLocale);
    submitJson(e, {}, Routes.set_locale_path({ locale: newLocale }));
  };

  const fetchUser = () =>
    fetchJson(Routes.login_user_path(), (result) => {
      User.current = result;
      setUserLoaded(true);
    });

  const onLogIn = () => {
    User.current = {};
    setUserLoaded(false);
    setLocale(null);
  };

  useEffect(() => {
    if (!locale) {
      fetchTranslations();
    }
  }, [locale]);

  useEffect(() => {
    if (!userLoaded) {
      fetchUser();
    }
  }, [userLoaded]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        {!locale || !userLoaded ? <Loading /> : <Router onLogIn={onLogIn} locale={locale} setLocale={handleLocaleChange} />}
      </BrowserRouter>
    </ThemeProvider>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(<Application />, document.getElementById("app"));
});

window.User = {
  current: {},

  loggedUserId() {
    return this.current.id;
  },

  loggedIn() {
    return !!this.current.id;
  },

  invalidateSessionUntilRefresh() {
    this.current.current_farm.id = -1;
  },
  
  currentFarmId() {
    return this.current.current_farm ? this.current.current_farm.id : null;
  },
  currentFarmAnimalTypes() {
    let types = [];
    if(this?.current?.current_farm){
      if(this.current.current_farm?.sheep) types.push("sheep")
      if(this.current.current_farm?.goat) types.push("goat")
    }
    return types;
  },
  accessAllFarms() {
    return this.current?.access_all_farms;
  },

  termsAgreed() {
    return !!this.current.terms;
  },

  hasPermission(permissionCode) {
    if (this.current.permissions) {
      return this.current.permissions.indexOf(permissionCode) != -1;
    } else {
      return false;
    }
  },

  allFeatures() {
    return this.current.current_farm && this.current.current_farm.all_features;
  }
};

window.I18n = {
  keys: {},
  locale: null,
  keySeparator: '.',
  available_locales: [],
  default_locale: null,

  t: function (k, params) {
    let p = params || {};
    let translation = undefined;
    let locale = p.locale || this.locale;
    let keys = this.keys[locale] || {};

    if (p.scope) {
      translation = (keys[p.scope][k] || "").slice(0); // clone
    } else if (typeof keys[k] == "object") {
      if (I18n.fallbacks && locale != I18n.default_locale ) {
        // Get translations from default locale and merge translations from this locale, so they take precedence.
        // This is done in case this locale has missing translations.
        return Object.assign(I18n.t(k, Object.assign(p, { locale: I18n.default_locale })), keys[k]);
      } else {
        return keys[k];
      }
    } else {
      var nested_k = k?.split('.') || [undefined];
      translation = keys;
      nested_k.forEach(key => {
        if (!translation) return
        translation = translation[key];
      })
      translation = (translation || "")?.slice(0);
    }

    if (typeof translation !== "string") {
      // Fallback to default locale
      if (I18n.fallbacks && locale != I18n.default_locale) {
        return I18n.t(k, Object.assign(p, { locale: I18n.default_locale }));
      }
      return k;
    }

    for (let paramKey in p) {
      if (paramKey != "scope") {
        translation = translation.replace(
          new RegExp("%{" + paramKey + "}", "ig"),
          p[paramKey]
        );
      }
    }
    return translation;
  },
};
