import moment from "moment";

const isPresent = (value) => value || value === 0;

const formattedDate = (date_str) => {
  return date_str ? moment(date_str).format(I18n.t("date_format")) : '';
}

const formatDecimal = (value, decimals = 2, keepIntegers = false) => {
  if (keepIntegers && typeof value === "number" && Number.isInteger(value)) {
    return value
  }
  if (typeof value === 'string' || value instanceof String) {
    return value.replace(/\./, ",")
  }
  else {
    return value.toFixed(decimals).replace(/\./, ",");
  }
}

export {isPresent, formattedDate, formatDecimal};