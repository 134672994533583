import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  errorText: {
    paddingTop: "5px",
    marginBottom: "5px",
    color: theme.palette.error.main,
    fontSize: "0.75rem",
  },
}));

const FieldErrors = ({ errorData }) => {
  const classes = useStyles();
  if ((errorData || []).length > 0) {
    return (
      <div className={classes.errorText}>
        {errorData.map((e, i) => {
          return <div key={i}>{e.message}</div>;
        })}
      </div>
    );
  }

  return null;
}

export default FieldErrors;