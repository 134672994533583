import React, { useState } from "react";
import { Routes, fetchJson, submitJson } from "@helpers";
import "@stylesheets/terms.scss"
import {Typography} from "@material-ui/core"

import { ExitToAppRounded as LogoutIcon, Language as LanguageIcon } from "@material-ui/icons";

import CheckBox from "@components/common/check_box";
import Link from "@components/common/link"
import Button from "@components/common/button"
const TermsForm = ({onAccept = null}) => {

  const [authAgreement, setAuthAgreement] = useState(false)
  const [terms, setTerms] = useState(false)
  const [error, setError] = useState(null)

  const terms_of_use_categories = [
    {id: 1, count: 4, header: false},
    {id: 2, count: 3, header: true},
    {id: 3, count: 5, header: true},
    {id: 4, count: 3, header: true},
    {id: 5, count: 4, header: true},
    {id: 6, count: 1, header: true},
    {id: 7, count: 1, header: true},
    {id: 8, count: 1, header: true},
    {id: 9, count: 1, header: true},
    {id: 10, count: 2, header: true},
    {id: 11, count: 1, header: true},
    {id: 12, count: 2, header: true},
    {id: 13, count: 2, header: true},
    {id: 14, count: 2, header: true},
  ]

  function toggle(value){
    return !value;
  }

  const acceptTerms = () =>{
    if(!authAgreement || !terms){
      setError(true);
      return;
    }
    fetchJson(Routes.agree_terms_path(), (result) => {
      setError(false);
      window.location.reload(false);
    }, (err) => {
      setError(true);
    });
  }
  const isLoggedIn = User.loggedIn();

  const handleLocaleChange = (e, newLocale) => {
    I18n.locale = newLocale;
    submitJson(e, {}, Routes.set_locale_path({ locale: newLocale }), () => {
      window.location.reload();
    });
  };

  return <div className="terms-wrapper">
  {isLoggedIn && <>
    <Link to="/logout" style={{ marginRight: "1rem" }}>
    <LogoutIcon style={{ transform: "rotateZ(180deg)", marginBottom: "-6px"}} /> {I18n.t("sign_out")}</Link>
    </>}
  <Link to="#" onClick={(e) => handleLocaleChange(e, I18n.locale == "fi" ? "sv" : "fi")}>
    <LanguageIcon style={{ marginBottom: "-6px" }} /> {I18n.locale == "fi" ? I18n.t("in_swedish") : I18n.t("in_finnish")}
  </Link> <br /> <br />
  <Typography variant="h2" style={{marginBottom: "1rem"}}>{I18n.t("terms_title")}</Typography>
  <p>
  {I18n.t("terms_subtitle")}
  </p>
  <Typography variant="h3">{I18n.t("terms_auth_agreement")}</Typography>
  <ol>
      {[...Array(6).keys()].map(v =>{
        return <li key={v}>{I18n.t(`terms_auth_agreement_${v+1}`)}</li>
      })}
  </ol>
  
  <CheckBox
    label={I18n.t("terms_auth_checkbox")}
    handleChange={() => setAuthAgreement(toggle)}
    color='primary'
    checked={authAgreement}
  />
  <br/><br/>
  <Typography variant="h3">{I18n.t("terms_of_use")}</Typography>
  <div className="scroll-div" >
    {Object.values(terms_of_use_categories).map(c => {
      return <>
      {c.header && <h4 key={`h_${c.id}`}>{I18n.t(`terms_of_use_h_${c.id}`)}</h4>}
      {[...Array(c.count).keys()].map(p_id =>{
        return <p key={`p_${p_id + 1}`}>{I18n.t(`terms_of_use_p_${c.id}_${p_id + 1}`)}</p>
      })}
      </>
    })}
  </div>
  <br />
  
  <CheckBox
    label={I18n.t("terms_of_use_checkbox")}
    handleChange={() => setTerms(toggle)}
    color='primary'
    checked={terms}
  />
  
  <br /><br />
  <Button disabled={!authAgreement || !terms} onClick={onAccept ? onAccept : acceptTerms}>{I18n.t("terms_continue_button")}</Button>
  </div>;
};

export default TermsForm;
