import React from "react";
import { Badge, Button as MuiButton } from "@material-ui/core";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";

const BTN_COLORS = ["default", "inherit", "primary", "secondary", "link"];

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    borderRadius: "0.75rem",
    margin: `${theme.spacing(1)}px 0`,
    fontSize: "1rem",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

// Create button specific theme
const createButtonTheme = (color, colorVariant) => (theme) =>
  createTheme({
    ...theme,
    palette: {
      primary: {
        main: theme.palette[color || "primary"][colorVariant || 'main'],
        contrastText: theme.palette[color || "primary"].contrastText,
      },
      default: {
        main: theme.palette[color || "default"][colorVariant || 'main'],
        contrastText: theme.palette[color || "default"].contrastText,
      },
    },
  });

const Button = ({ children, icon, color = "primary", colorVariant, badge, ...rest }) => {
  const classes = useStyles();
  let btnColor = !BTN_COLORS.includes(color) ? "primary" : color;
  let iconComponent = icon ? React.createElement(icon, { className: classes.icon, style: !children ?{marginRight: "0"}: {} }) : null;
  return (
    <ThemeProvider theme={createButtonTheme(color, colorVariant)}>
      <MuiButton
        classes={{ root: classes.root }}
        variant="contained"
        color={btnColor}
        {...rest}
      >
        {icon ? iconComponent : null}
        {badge ? <Badge badgeContent={badge} color={color}>
          {children}
        </Badge>
        : children
        }
      </MuiButton>
    </ThemeProvider>
  );
};

export default Button;
