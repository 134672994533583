import React, { Suspense, lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import Loading from "@components/common/loading_indicator";
import AuthorizedRoute from "@components/common/authorized_route";
import Route from "@components/common/route";

// Lazy load layouts, so the whole application is not loaded at first.
const App = lazy(() => import("@layouts/application"));
const Login = lazy(() => import("@layouts/login"));

const Router = ({ onLogIn, locale, setLocale }) => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Redirect exact path="/" to="/animals" />
      <Route path="/login" component={Login} componentProps={{ onLogIn, locale, setLocale }} />
      <Route exact path="/logout" component={Login} />
      <AuthorizedRoute key={locale} path="/" permission="view_dashboard" component={App} componentProps={{ locale, setLocale }} />
    </Switch>
  </Suspense>
);

export default Router;
