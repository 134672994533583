import React from "react";
import { Grid } from "@material-ui/core";
import { SettingsRounded } from "@material-ui/icons";

LoadingIndicator.defaultProps = {
  iconProps: {
    style: { fontSize: 100 },
  },
  containerProps: {
    style: { padding: 15, marginTop: 64, marginBottom: 64  },
  },
};

export default function LoadingIndicator({ containerProps, iconProps }) {
  return (
    <Grid container justifyContent="center" alignItems="center" {...containerProps}>
      <SettingsRounded className="animate-spin" {...iconProps} />
    </Grid>
  );
}
