import React, { useState, useEffect } from "react";
import { FormControl, FormControlLabel, Checkbox, Radio } from "@material-ui/core";

import FieldErrors from "@components/error/field_errors";

const CheckBox = ({
  inputRef,
  label,
  name,
  initialValue = false,
  errorData,
  handleChange,
  radio,
  ...rest
}) => {
  const [value, setValue] = useState(!!initialValue);
  useEffect(() => {
    setValue(initialValue || false);
  }, [initialValue]);

  const handleValue = (e) => {
    setValue(e);
    if (handleChange) {
      handleChange(e);
    }
  };

  const Component = radio ? Radio : Checkbox;

  return (
    <FormControl>
      <input
        type="hidden"
        name={name}
        value={value}
        ref={inputRef}
      />
      <FormControlLabel
        control={
          <Component
            checked={value == true}
            onChange={(e) => handleValue(e.target.checked)}
            name={name}
            {...rest}
          />
        }
        {...rest}
        label={label}
      />
      <FieldErrors errorData={errorData} />
    </FormControl>
  );
};

export default CheckBox;
