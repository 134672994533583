import React from "react";
import { Route as RouterRoute } from "react-router-dom";

const Route = ({ component: Component, componentProps, render, ...rest }) => (
  <RouterRoute
    {...rest}
    render={(props) =>
      Component ? <Component {...props} {...componentProps} /> : render(props)
    }
  />
);

export default Route;
