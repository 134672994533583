import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: [
      "NotoSans-Regular",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
    ],
    h1: {
      fontSize: "3rem",
      marginBottom: 50,
    },
    h2: {
      fontSize: "2rem",
      marginBottom: 50,
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: "1.5",
      fontFamily: "NotoSans-Bold",
    },
    h4: {
      fontSize: "1.25rem",
    },
    h5: {
      fontSize: "1rem",
    },
    h6: {
      fontFamily: "NotoSans-Bold",
      fontSize: "0.875rem",
      textTransform: "uppercase",
      color: "#315670b3",
    },
  },
  palette: {
    primary: {
      main: "#2d98da",
    },
    default: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#d6e1e9",
      contrastText: "#6d8293",
    },
    error: {
      main: "#fc5c65",
    },
    warning: {
      main: "#fd9745",
      contrastText: "white",
    },
    success: {
      main: "#20bf6b",
      dark: "#5d8256",
      contrastText: "white",
    },
    ultrasonic: {
      main: "#4b7bec",
    },
    purple: {
      main: "#a65eea",
    },
    turqoise: {
      main: "#10b9b1",
      contrastText: "white",
    },
    fadedGreen: {
      main: "#63a598",
      contrastText: "white",
    },
    text: {
      primary: "#4b6584",
      secondary: "#1d1d1d",
    },
    background: {
      default: "white",
      primary: "#2d7e90",
      secondary: "#2fabc5",
      tertiary: "#59bacf",
    },
    action: {
      disabledBackground: "#f1f1f1",
    },
    unselected: {
      main: "#f1f1f1",
    },
    proAgria: {
      main: '#48B123',
    },
    divider: "#ced4da",
  },
  overrides: {
    MuiButton: {
      label: {
        lineHeight: "1.8rem",
      },
    },
  },
});

export default theme;