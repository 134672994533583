export const fetchJson = async (path, onSuccess, onError = () => {}) => {
  try{
    const response = await fetch(path, {
      headers: {
      "X-SESSION-FARM-ID": User.currentFarmId()
    }});
    if(!response.ok){
      if(response.status == 403 || response.status == 422){
        window.location.reload(false);
      }else{
        onError(response);
      }
    }else{
      const result = await response.json();
      onSuccess(result);
    }
  }catch (e) {
    onError(e)
  }
};

const onError = (
  setErrors,
  errors = {
    server: [{ message: I18n.t("server_error_message") }],
  }
) => {
  setErrors(errors);
  window.scrollTo(0, 0);
};

export const submitJson = async (
  e,
  formData,
  path,
  onSuccess = () => { },
  setErrors = () => { }
) => {
  try {
    if (e)
      e.preventDefault();

    const response = await fetch(path, {
      method: "post",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
        "X-SESSION-FARM-ID": User.currentFarmId(),
      },
    });
    if (!response.ok) {
      if(response.status == 403 || response.status == 422){
        window.location.reload(false);
      }
      const errorJson = await response.json();
      onError(setErrors, errorJson.errors);
    } else {
      setErrors({});
      const responseJson = await response.json();
      if (responseJson) {
        onSuccess(responseJson);
      }
    }
  } catch (ex) {
    // Catch non JSON responses and set with default server error.
    console.log(ex)
    onError(setErrors);
  }
};
