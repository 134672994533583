import React from "react";
import { Route, Redirect } from "react-router-dom";
import AccessDenied from "@components/error/access_denied";
import TermsForm from "../../layouts/terms";

const AuthorizedRoute = ({
  permission: Permission,
  component: Component,
  componentProps,
  render,
  ...rest
}) => {
  let hasPermission = false;
  const isLoggedIn = User.loggedIn();
  const termsAgreed = User.termsAgreed();

  if (isLoggedIn) {
    (Array.isArray(Permission) ? Permission : [Permission]).forEach((p) => {
      hasPermission = hasPermission || User.hasPermission(p);
    });
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        hasPermission ? (
          termsAgreed ? (
            Component ? (
              <Component {...props} {...componentProps} />
            ) : (
                render(props)
              )
          ) : (
            <TermsForm />
          )
        ) : isLoggedIn ? (
          <AccessDenied />
        ) : (
              <Redirect to="/login" />
            )
      }
    />
  );
};
export default AuthorizedRoute;
