import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link as MuiLink } from "@material-ui/core";

const Link = ({ to, children, ...rest }) => (
  <MuiLink to={to} component={RouterLink} {...rest}>
    {children}
  </MuiLink>
);

export default Link;
