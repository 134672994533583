/* rails-erb-loader-dependencies ../config/routes */
var routes = {};

(function() {
    /*
File generated by js-routes 1.4.9
Based on Rails 6.0.3.4 routes of Kare::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// agree_terms => /api/agree_terms(.:format)
  // function(options)
  agree_terms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"agree_terms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// animal_action_create => /api/animal/action(.:format)
  // function(options)
  animal_action_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_action_edit => /api/animal/action/:id/edit(.:format)
  // function(id, options)
  animal_action_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"action",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_action_remove => /api/animal/action/:id/remove(.:format)
  // function(id, options)
  animal_action_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"action",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_action_update => /api/animal/action/:id/update(.:format)
  // function(id, options)
  animal_action_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"action",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_add_ancestor => /api/ancestor_suplement/create/:child_id(.:format)
  // function(child_id, options)
  animal_add_ancestor_path: Utils.route([["child_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ancestor_suplement",false],[2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[3,"child_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_birth => /api/animal/birth(/:mother_id)(.:format)
  // function(options)
  animal_birth_path: Utils.route([["mother_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"birth",false],[2,[1,[2,[7,"/",false],[3,"mother_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// animal_birthing_edit => /api/animal/birth/:id/edit(.:format)
  // function(id, options)
  animal_birthing_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"birth",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_birthing_remove => /api/animal/birth/:id/remove(.:format)
  // function(id, options)
  animal_birthing_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"birth",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_birthing_update => /api/animal/birth/:id/update(.:format)
  // function(id, options)
  animal_birthing_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"birth",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_combine_breeds => /api/animal/combine_breeds(.:format)
  // function(options)
  animal_combine_breeds_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"combine_breeds",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_create => /api/animal/create(.:format)
  // function(options)
  animal_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_create_comment => /api/animal/comment/create(.:format)
  // function(options)
  animal_create_comment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"comment",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_delete => /api/animal/:animal_id/delete(.:format)
  // function(animal_id, options)
  animal_delete_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[3,"animal_id",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_disease_create => /api/animal/disease(.:format)
  // function(options)
  animal_disease_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"disease",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_disease_edit => /api/animal/disease/:id/edit(.:format)
  // function(id, options)
  animal_disease_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"disease",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_disease_remove => /api/animal/disease/:id/remove(.:format)
  // function(id, options)
  animal_disease_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"disease",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_disease_update => /api/animal/disease/:id/update(.:format)
  // function(id, options)
  animal_disease_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"disease",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_europ_create => /api/animal/europ/create(.:format)
  // function(options)
  animal_europ_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"europ",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_europ_edit => /api/animal/europ/:id/edit(.:format)
  // function(id, options)
  animal_europ_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"europ",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_europ_remove => /api/animal/europ/:id/remove(.:format)
  // function(id, options)
  animal_europ_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"europ",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_europ_update => /api/animal/europ/:id/update(.:format)
  // function(id, options)
  animal_europ_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"europ",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_event => /api/animal/event/:event_id(.:format)
  // function(event_id, options)
  animal_event_path: Utils.route([["event_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"event",false],[2,[7,"/",false],[2,[3,"event_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_find => /api/animal/find/:eu_id(.:format)
  // function(eu_id, options)
  animal_find_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"find",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_find_idlas => /api/animal/find_idlas/:idlas(.:format)
  // function(idlas, options)
  animal_find_idlas_path: Utils.route([["idlas",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"find_idlas",false],[2,[7,"/",false],[2,[3,"idlas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get => /api/animal/get/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_action_data => /api/animal/action/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_action_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"action",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_ancestors_suplement_form_data => /api/ancestor_suplement/get_data(/:animal_id)(.:format)
  // function(options)
  animal_get_ancestors_suplement_form_data_path: Utils.route([["animal_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ancestor_suplement",false],[2,[7,"/",false],[2,[6,"get_data",false],[2,[1,[2,[7,"/",false],[3,"animal_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// animal_get_birth_data => /api/animal/birth/form/:mother_id(.:format)
  // function(mother_id, options)
  animal_get_birth_data_path: Utils.route([["mother_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"birth",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"mother_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_birthings => /api/animal/get_birthings/:animal_id(.:format)
  // function(animal_id, options)
  animal_get_birthings_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_birthings",false],[2,[7,"/",false],[2,[3,"animal_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_comments => /api/animal/get_comments/:animal_id(.:format)
  // function(animal_id, options)
  animal_get_comments_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_comments",false],[2,[7,"/",false],[2,[3,"animal_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_disease_data => /api/animal/disease/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_disease_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"disease",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_europ_data => /api/animal/europ/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_europ_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"europ",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_event_types => /api/animal/get_event_types/:eu_id(.:format)
  // function(eu_id, options)
  animal_get_event_types_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_event_types",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_events => /api/animal/get_events/:eu_id(.:format)
  // function(eu_id, options)
  animal_get_events_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_events",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_ewe_results => /api/animal/get_ewe_results/:animal_id(.:format)
  // function(animal_id, options)
  animal_get_ewe_results_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_ewe_results",false],[2,[7,"/",false],[2,[3,"animal_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_family_tree => /api/animal/get_family_tree/:animal_id(.:format)
  // function(animal_id, options)
  animal_get_family_tree_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_family_tree",false],[2,[7,"/",false],[2,[3,"animal_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_form_data => /api/animal/form(/:animal_id)(.:format)
  // function(options)
  animal_get_form_data_path: Utils.route([["animal_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"form",false],[2,[1,[2,[7,"/",false],[3,"animal_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// animal_get_goatstructureevaluation_data => /api/animal/goat_structure_evaluation/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_goatstructureevaluation_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"goat_structure_evaluation",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_image => /api/animal/get_image/:animal_id(.:format)
  // function(animal_id, options)
  animal_get_image_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_image",false],[2,[7,"/",false],[2,[3,"animal_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_parent_data => /api/animal/get_parent_data/:eu_id(.:format)
  // function(eu_id, options)
  animal_get_parent_data_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_parent_data",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_profile_data => /api/animal/get_profile_data/:eu_id(.:format)
  // function(eu_id, options)
  animal_get_profile_data_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_profile_data",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_purchase_data => /api/animal/purchase/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_purchase_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"purchase",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_removal_data => /api/animal/removal/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_removal_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"removal",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_shearing_data => /api/animal/shearing/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_shearing_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"shearing",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_slaughter_info_data => /api/animal/slaughter_info/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_slaughter_info_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"slaughter_info",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_structure_evaluation => /api/animal/get_structure_evaluation/:animal_id(.:format)
  // function(animal_id, options)
  animal_get_structure_evaluation_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_structure_evaluation",false],[2,[7,"/",false],[2,[3,"animal_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_transfer_data => /api/animal/transfer/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_transfer_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"transfer",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_vaccination_data => /api/animal/vaccination/form/:animal_ids(.:format)
  // function(animal_ids, options)
  animal_get_vaccination_data_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"vaccination",false],[2,[7,"/",false],[2,[6,"form",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_get_weighing_data => /api/animal/weighing/form(.:format)
  // function(options)
  animal_get_weighing_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"weighing",false],[2,[7,"/",false],[2,[6,"form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_weightings => /api/animal/get_weightings/:animal_id(.:format)
  // function(animal_id, options)
  animal_get_weightings_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"get_weightings",false],[2,[7,"/",false],[2,[3,"animal_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_get_wool_data => /api/animal/wool/form(.:format)
  // function(options)
  animal_get_wool_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"wool",false],[2,[7,"/",false],[2,[6,"form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_goatstructureevaluation => /api/animal/goat_structure_evaluation/create(.:format)
  // function(options)
  animal_goatstructureevaluation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"goat_structure_evaluation",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_goatstructureevaluation_edit => /api/animal/goat_structure_evaluation/:id/edit(.:format)
  // function(id, options)
  animal_goatstructureevaluation_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"goat_structure_evaluation",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_goatstructureevaluation_remove => /api/animal/goat_structure_evaluation/:id/remove(.:format)
  // function(id, options)
  animal_goatstructureevaluation_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"goat_structure_evaluation",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_goatstructureevaluation_update => /api/animal/goat_structure_evaluation/:id/update(.:format)
  // function(id, options)
  animal_goatstructureevaluation_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"goat_structure_evaluation",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_group => /api/animal/group/:id(.:format)
  // function(id, options)
  animal_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"group",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_group_create => /api/animal/group/create(.:format)
  // function(options)
  animal_group_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"group",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_group_list => /api/animal/group/list(.:format)
  // function(options)
  animal_group_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"group",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_group_remove => /api/animal/group/remove/:id(.:format)
  // function(id, options)
  animal_group_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"group",false],[2,[7,"/",false],[2,[6,"remove",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_group_update => /api/animal/group/update/:id(.:format)
  // function(id, options)
  animal_group_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"group",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_incomplete_purchases => /api/animal/purchase/incomplete(.:format)
  // function(options)
  animal_incomplete_purchases_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"purchase",false],[2,[7,"/",false],[2,[6,"incomplete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_information_import => /api/animal/information_import/:type(.:format)
  // function(type, options)
  animal_information_import_path: Utils.route([["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"information_import",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_information_import_form => /api/animal/information_import/:type(.:format)
  // function(type, options)
  animal_information_import_form_path: Utils.route([["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"information_import",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_list => /api/animal/list(.:format)
  // function(options)
  animal_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_list_filters => /api/animal/list_filters(.:format)
  // function(options)
  animal_list_filters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"list_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_list_info => /api/animal/list_info(.:format)
  // function(options)
  animal_list_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"list_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_purchase => /api/animal/purchase(.:format)
  // function(options)
  animal_purchase_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"purchase",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_purchase_edit => /api/animal/purchase/:id/edit(.:format)
  // function(id, options)
  animal_purchase_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"purchase",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_purchase_remove => /api/animal/purchase/:id/remove(.:format)
  // function(id, options)
  animal_purchase_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"purchase",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_purchase_update => /api/animal/purchase/:id/update(.:format)
  // function(id, options)
  animal_purchase_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"purchase",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_removal_create => /api/animal/removal(.:format)
  // function(options)
  animal_removal_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"removal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_removal_edit => /api/animal/removal/:id/edit(.:format)
  // function(id, options)
  animal_removal_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"removal",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_removal_remove => /api/animal/removal/:id/remove(.:format)
  // function(id, options)
  animal_removal_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"removal",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_removal_update => /api/animal/removal/:id/update(.:format)
  // function(id, options)
  animal_removal_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"removal",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_remove_ancestor => /api/ancestor_suplement/remove/:child_id(.:format)
  // function(child_id, options)
  animal_remove_ancestor_path: Utils.route([["child_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ancestor_suplement",false],[2,[7,"/",false],[2,[6,"remove",false],[2,[7,"/",false],[2,[3,"child_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_shearing_create => /api/animal/shearing(.:format)
  // function(options)
  animal_shearing_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"shearing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_shearing_edit => /api/animal/shearing/:id/edit(.:format)
  // function(id, options)
  animal_shearing_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"shearing",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_shearing_remove => /api/animal/shearing/:id/remove(.:format)
  // function(id, options)
  animal_shearing_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"shearing",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_shearing_update => /api/animal/shearing/:id/update(.:format)
  // function(id, options)
  animal_shearing_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"shearing",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_show => /api/animal/show/:eu_id(.:format)
  // function(eu_id, options)
  animal_show_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"show",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_slaughter_info_update => /api/animal/slaughter_info/:animal_ids/update(.:format)
  // function(animal_ids, options)
  animal_slaughter_info_update_path: Utils.route([["animal_ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"slaughter_info",false],[2,[7,"/",false],[2,[3,"animal_ids",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_transfer => /api/animal/transfer/create(.:format)
  // function(options)
  animal_transfer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"transfer",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_transfer_edit => /api/animal/transfer/:id/edit(.:format)
  // function(id, options)
  animal_transfer_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"transfer",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_transfer_remove => /api/animal/transfer/:id/remove(.:format)
  // function(id, options)
  animal_transfer_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"transfer",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_transfer_update => /api/animal/transfer/:id/update(.:format)
  // function(id, options)
  animal_transfer_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"transfer",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_ultrasonic => /api/animal/ultrasonic/:id(.:format)
  // function(id, options)
  animal_ultrasonic_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"ultrasonic",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_ultrasonic_create => /api/animal/ultrasonic(.:format)
  // function(options)
  animal_ultrasonic_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"ultrasonic",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_ultrasonic_edit => /api/animal/ultrasonic/:id/edit(.:format)
  // function(id, options)
  animal_ultrasonic_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"ultrasonic",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_ultrasonic_remove => /api/animal/ultrasonic/:id/remove(.:format)
  // function(id, options)
  animal_ultrasonic_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"ultrasonic",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_ultrasonic_update => /api/animal/ultrasonic/:id/update(.:format)
  // function(id, options)
  animal_ultrasonic_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"ultrasonic",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_update => /api/animal/:animal_id/update(.:format)
  // function(animal_id, options)
  animal_update_path: Utils.route([["animal_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[3,"animal_id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_update_ancestor => /api/ancestor_suplement/update/:child_id(.:format)
  // function(child_id, options)
  animal_update_ancestor_path: Utils.route([["child_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"ancestor_suplement",false],[2,[7,"/",false],[2,[6,"update",false],[2,[7,"/",false],[2,[3,"child_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_update_comment => /api/animal/comment/:id(.:format)
  // function(id, options)
  animal_update_comment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"comment",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_vaccination_create => /api/animal/vaccination(.:format)
  // function(options)
  animal_vaccination_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"vaccination",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_vaccination_edit => /api/animal/vaccination/:id/edit(.:format)
  // function(id, options)
  animal_vaccination_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"vaccination",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_vaccination_remove => /api/animal/vaccination/:id/remove(.:format)
  // function(id, options)
  animal_vaccination_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"vaccination",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_vaccination_show => /api/animal/vaccination/:id(.:format)
  // function(id, options)
  animal_vaccination_show_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"vaccination",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_vaccination_update => /api/animal/vaccination/:id/update(.:format)
  // function(id, options)
  animal_vaccination_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"vaccination",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_validate_breed => /api/animal/validate_breed(.:format)
  // function(options)
  animal_validate_breed_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"validate_breed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_weighing_create => /api/animal/weighing(.:format)
  // function(options)
  animal_weighing_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"weighing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_weighing_edit => /api/animal/weighing/:id/edit(.:format)
  // function(id, options)
  animal_weighing_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"weighing",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_weighing_remove => /api/animal/weighing/:id/remove(.:format)
  // function(id, options)
  animal_weighing_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"weighing",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_weighing_show => /api/animal/weighing/:id(.:format)
  // function(id, options)
  animal_weighing_show_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"weighing",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_weighing_update => /api/animal/weighing/:id/update(.:format)
  // function(id, options)
  animal_weighing_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"weighing",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_wool => /api/animal/wool/:id(.:format)
  // function(id, options)
  animal_wool_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"wool",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// animal_wool_create => /api/animal/wool(.:format)
  // function(options)
  animal_wool_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"wool",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// animal_wool_edit => /api/animal/wool/:id/edit(.:format)
  // function(id, options)
  animal_wool_edit_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"wool",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_wool_remove => /api/animal/wool/:id/remove(.:format)
  // function(id, options)
  animal_wool_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"wool",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// animal_wool_update => /api/animal/wool/:id/update(.:format)
  // function(id, options)
  animal_wool_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"animal",false],[2,[7,"/",false],[2,[6,"wool",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// apitome => /api/docs
  // function(options)
  apitome_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[6,"docs",false]]]]),
// apitome.root => /api/docs/
  // function(options)
  apitome_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[6,"docs",false]]]],[7,"/",false]]),
// create_farm => /api/farm/create(.:format)
  // function(options)
  create_farm_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// farm_event_types => /api/farm/event_types(.:format)
  // function(options)
  farm_event_types_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"event_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// farm_events => /api/farm/events(.:format)
  // function(options)
  farm_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// farm_free_codes => /api/farm/free_codes(.:format)
  // function(options)
  farm_free_codes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"free_codes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// farm_list => /api/farm/list(.:format)
  // function(options)
  farm_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// farm_locations => /api/farm/locations(.:format)
  // function(options)
  farm_locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// farm_show => /api/farm(/:code)(.:format)
  // function(options)
  farm_show_path: Utils.route([["code",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[1,[2,[7,"/",false],[3,"code",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// farm_show_ui => /farm/show(/:code)(.:format)
  // function(options)
  farm_show_ui_path: Utils.route([["code",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"show",false],[2,[1,[2,[7,"/",false],[3,"code",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// farm_unsuccessful_events_count => /api/farm/unsuccessful_events_count(.:format)
  // function(options)
  farm_unsuccessful_events_count_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"unsuccessful_events_count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// farm_update => /api/farm/update(/:id)(.:format)
  // function(options)
  farm_update_path: Utils.route([["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"update",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// file_download => /api/file/download/:file_id(.:format)
  // function(file_id, options)
  file_download_path: Utils.route([["file_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"file",false],[2,[7,"/",false],[2,[6,"download",false],[2,[7,"/",false],[2,[3,"file_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// file_image_original => /api/file/image_original/:file_id(.:format)
  // function(file_id, options)
  file_image_original_path: Utils.route([["file_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"file",false],[2,[7,"/",false],[2,[6,"image_original",false],[2,[7,"/",false],[2,[3,"file_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// file_image_thumbnail => /api/file/image_thumbnail/:file_id(.:format)
  // function(file_id, options)
  file_image_thumbnail_path: Utils.route([["file_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"file",false],[2,[7,"/",false],[2,[6,"image_thumbnail",false],[2,[7,"/",false],[2,[3,"file_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// file_upload => /api/file/upload/:upload_id(.:format)
  // function(upload_id, options)
  file_upload_path: Utils.route([["upload_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"file",false],[2,[7,"/",false],[2,[6,"upload",false],[2,[7,"/",false],[2,[3,"upload_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// get_report => /api/get_report(.:format)
  // function(options)
  get_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"get_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// get_report_form_options => /api/get_report_form_options(.:format)
  // function(options)
  get_report_form_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"get_report_form_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// index_data => /api/index_data(.:format)
  // function(options)
  index_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"index_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login => /api/login(.:format)
  // function(options)
  login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login_change_password => /api/login/change_password(.:format)
  // function(options)
  login_change_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"change_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// login_change_password_token => /api/login/change_password_token(.:format)
  // function(options)
  login_change_password_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"change_password_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// login_request_password_change => /api/login/request_password_change(.:format)
  // function(options)
  login_request_password_change_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"request_password_change",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// login_system_announcements => /api/login/system_announcements/:locale(.:format)
  // function(locale, options)
  login_system_announcements_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"system_announcements",false],[2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// login_user => /api/login/user(.:format)
  // function(options)
  login_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// logout => /api/logout(.:format)
  // function(options)
  logout_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mating_create => /api/mating/create(.:format)
  // function(options)
  mating_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mating",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mating_find_animal => /api/mating/find/:eu_id(.:format)
  // function(eu_id, options)
  mating_find_animal_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mating",false],[2,[7,"/",false],[2,[6,"find",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mating_get_inbreed_matrix => /api/mating/inbreed_matrix(.:format)
  // function(options)
  mating_get_inbreed_matrix_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mating",false],[2,[7,"/",false],[2,[6,"inbreed_matrix",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mating_list => /api/mating/list(.:format)
  // function(options)
  mating_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mating",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mating_list_info => /api/mating/list_info(.:format)
  // function(options)
  mating_list_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mating",false],[2,[7,"/",false],[2,[6,"list_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mating_remove => /api/mating/remove/:id(.:format)
  // function(id, options)
  mating_remove_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mating",false],[2,[7,"/",false],[2,[6,"remove",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mating_show => /api/mating/show(/:animal_type)(.:format)
  // function(options)
  mating_show_path: Utils.route([["animal_type",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mating",false],[2,[7,"/",false],[2,[6,"show",false],[2,[1,[2,[7,"/",false],[3,"animal_type",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// mating_update => /api/mating/update(/:id)(.:format)
  // function(options)
  mating_update_path: Utils.route([["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"mating",false],[2,[7,"/",false],[2,[6,"update",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// password_change => /login/psw/:token(.:format)
  // function(token, options)
  password_change_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"login",false],[2,[7,"/",false],[2,[6,"psw",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pedigree_create => /api/pedigree/create/:eu_id(.:format)
  // function(eu_id, options)
  pedigree_create_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"pedigree",false],[2,[7,"/",false],[2,[6,"create",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pedigree_list => /api/pedigree(.:format)
  // function(options)
  pedigree_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"pedigree",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pedigree_remove => /api/pedigree/remove/:eu_id(.:format)
  // function(eu_id, options)
  pedigree_remove_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"pedigree",false],[2,[7,"/",false],[2,[6,"remove",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pedigree_show => /api/pedigree/:eu_id(.:format)
  // function(eu_id, options)
  pedigree_show_path: Utils.route([["eu_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"pedigree",false],[2,[7,"/",false],[2,[3,"eu_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pedigrees_set => /api/pedigree/set(.:format)
  // function(options)
  pedigrees_set_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"pedigree",false],[2,[7,"/",false],[2,[6,"set",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// retry_elra_job => /api/system/elra_jobs/retry/:id(.:format)
  // function(id, options)
  retry_elra_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"system",false],[2,[7,"/",false],[2,[6,"elra_jobs",false],[2,[7,"/",false],[2,[6,"retry",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// set_locale => /api/set_locale/:locale(.:format)
  // function(locale, options)
  set_locale_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"set_locale",false],[2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidebar_data => /api/sidebar(.:format)
  // function(options)
  sidebar_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"sidebar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// start_elra_job => /api/system/elra_jobs/start(.:format)
  // function(options)
  start_elra_job_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"system",false],[2,[7,"/",false],[2,[6,"elra_jobs",false],[2,[7,"/",false],[2,[6,"start",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// start_updater => /api/farm/updater/start(.:format)
  // function(options)
  start_updater_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"updater",false],[2,[7,"/",false],[2,[6,"start",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// system => /api/system(.:format)
  // function(options)
  system_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"system",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// translations => /api/translations(.:format)
  // function(options)
  translations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"translations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// updater_status => /api/farm/updater/status(/:farm_id)(.:format)
  // function(options)
  updater_status_path: Utils.route([["farm_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"farm",false],[2,[7,"/",false],[2,[6,"updater",false],[2,[7,"/",false],[2,[6,"status",false],[2,[1,[2,[7,"/",false],[3,"farm_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// user_change_farm => /api/user/change_farm(.:format)
  // function(options)
  user_change_farm_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"change_farm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_create => /api/user/create(.:format)
  // function(options)
  user_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_delete => /api/user/person_id/delete(.:format)
  // function(options)
  user_delete_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"person_id",false],[2,[7,"/",false],[2,[6,"delete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_get => /api/user/get(.:format)
  // function(options)
  user_get_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"get",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_get_form_data => /api/user/get_form_data/:tab(/:person_id)(.:format)
  // function(tab, options)
  user_get_form_data_path: Utils.route([["tab",true],["person_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"get_form_data",false],[2,[7,"/",false],[2,[3,"tab",false],[2,[1,[2,[7,"/",false],[3,"person_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// user_get_preference => /api/user/preferences/:key(.:format)
  // function(key, options)
  user_get_preference_path: Utils.route([["key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"preferences",false],[2,[7,"/",false],[2,[3,"key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_list => /api/user/list/:tab(.:format)
  // function(tab, options)
  user_list_path: Utils.route([["tab",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"list",false],[2,[7,"/",false],[2,[3,"tab",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_list_filters => /api/user/list_filters(.:format)
  // function(options)
  user_list_filters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"list_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_update => /api/user/:tab/:person_id/update(.:format)
  // function(tab, person_id, options)
  user_update_path: Utils.route([["tab",true],["person_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[3,"tab",false],[2,[7,"/",false],[2,[3,"person_id",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_update_preference => /api/user/preferences/:key(.:format)
  // function(key, options)
  user_update_preference_path: Utils.route([["key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"preferences",false],[2,[7,"/",false],[2,[3,"key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// weights_api_v1_slaughters => /api/1.0/slaughter/weights(.:format)
  // function(options)
  weights_api_v1_slaughters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"1",false],[2,[8,".",false],[2,[6,"0",false],[2,[7,"/",false],[2,[6,"slaughter",false],[2,[7,"/",false],[2,[6,"weights",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Namespace" + ".defaults is removed. Use " + "Namespace" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Namespace", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

}.call(routes));

export default routes.Namespace;
